<template>
  <div>
    <div class="text-h4 pt-5 mb-5">Реферральная программа</div>
    <referrals-table />
  </div>
</template>

<script>
import referralsTable from "@/components/ReferralsTable";

export default {
  name: "Referrals",
  components: { referralsTable },
};
</script>

<style scoped></style>
